import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/mark/di-net2/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Mission`}</h1>
    <p><strong parentName="p">{`DesignInquiry is a non-profit educational organization that explores pressing issues in design and culture. We are a collective of thinkers and makers devoted to extra-disciplinary exchange with a changing roster of designers, artists, scholars, teachers, architects, historians, critics, writers and other practitioners carrying out and sustaining our work.`}</strong></p>
    <p><strong parentName="p">{`DesignInquiry spearheads intensive team-based gatherings, shares the diverse outcomes and publications, influences design research and teaching methods, and inspires professional designers to rethink what design can be and can do.`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      